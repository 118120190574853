<template>
  <PageListLayout
    :title="`${editing ? 'Cập nhật' : 'Thêm'} yêu cầu báo giá`"
    noFilter
    useBack
    linkBack="/kinhdoanh/yeucaubaogia"
  >
    <template slot="side-right">
      <div class="d-flex">
        <v-btn
          v-if="editing"
          :loading="btnLoadingPrint"
          class="mr-4"
          @click="print(form)"
          outlined
          color="primary"
          dark
        >
          <v-icon class="mr-1"> mdi-printer</v-icon>
          In Yêu Cầu Báo giá
        </v-btn>
        <v-btn
          class="mr-4"
          depressed
          color="success"
          @click="submitUpdate"
          v-if="editing"
        >
          <v-icon left>mdi-account-edit</v-icon>
          Cập nhật
        </v-btn>
        <v-btn class="mr-4" depressed color="success" @click="submit" v-else>
          <v-icon left>mdi-account-plus </v-icon>
          Thêm mới yêu cầu báo giá
        </v-btn>
      </div>
    </template>
    <v-form ref="form">
      <v-card-title class="pt-0 pl-6">Yêu cầu báo giá</v-card-title>
      <v-layout column class="pl-6 pr-6">
        <v-row>
          <v-col cols="3" class="zero-vertical">
            <div class="label-form">Mã yêu cầu báo giá</div>
            <v-text-field
              v-model="form.code"
              :rules="codeRules"
              placeholder="Mã yêu cầu báo giá"
              outlined
              dense
              prepend-inner-icon="mdi-account"
              :loading="loadingCode"
              :disabled="editing"
            ></v-text-field>
          </v-col>
          <v-col cols="5" class="zero-vertical">
            <div class="label-form">Đơn vị phát sinh</div>
            <v-autocomplete
              prepend-inner-icon="mdi-folder-account"
              v-model="form.customer_id"
              :items="customersList"
              outlined
              dense
              item-text="name"
              item-value="id"
              @change="onChangeCustomers($event)"
              :rules="customer_idRules"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="2" class="zero-vertical">
            <div class="label-form">Ngày yêu cầu báo giá</div>
            <DateTimePicker
              v-model="form.request_date"
              :input-readonly="false"
              format="DD/MM/YYYY"
              :rules="request_dateRules"
              hideDetails="auto"
            ></DateTimePicker>
          </v-col>

          <v-col cols="2" class="zero-vertical">
            <div class="label-form">Ngày đề nghị trả kết quả</div>
            <DateTimePicker
              v-model="form.response_date"
              :input-readonly="false"
              format="DD/MM/YYYY"
              :rules="response_dateRules"
              hideDetails="auto"
            ></DateTimePicker>
          </v-col>
          <v-col cols="3" class="zero-vertical">
            <div class="label-form">Loại yêu cầu</div>
            <v-select
              prepend-inner-icon="mdi-format-list-bulleted"
              v-model="form.request_type"
              :items="requestTypeList"
              outlined
              dense
              item-text="name"
              item-value="value"
              :rules="request_typeRules"
              :disabled="editing"
            >
            </v-select>
          </v-col>
          <v-col cols="3" class="zero-vertical" v-if="form.request_type == 2">
            <div class="label-form">Mã hợp đồng thay thế</div>
            <v-autocomplete
              prepend-inner-icon="mdi-folder-account"
              v-model="form.contract_id"
              :items="listContract"
              outlined
              dense
              item-text="contract_code"
              item-value="id"
              :rules="contract_idRules"
            >
              <template slot="no-data">
                <div class="text-center">
                  {{
                    form.customer_id
                      ? "Chưa có hợp đồng nào"
                      : "Vui lòng chọn khách hàng"
                  }}
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <!-- Báo giá bổ sung -->
          <v-col cols="3" class="zero-vertical" v-if="form.request_type == 3">
            <div class="label-form">Mã hợp đồng bổ sung</div>
            <v-autocomplete
              prepend-inner-icon="mdi-folder-account"
              v-model="form.contract_id"
              :items="listBonusContract"
              outlined
              dense
              item-text="contract_code"
              item-value="id"
              :rules="bonusContract_idRules"
            >
              <template slot="no-data">
                <div class="text-center">
                  {{
                    form.customer_id
                      ? "Chưa có hợp đồng nào"
                      : "Vui lòng chọn khách hàng"
                  }}
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <!-- Báo giá bổ sung -->
          <v-col cols="12" class="zero-vertical">
            <div class="label-form">Ghi chú</div>
            <v-textarea
              v-model="form.description"
              placeholder="Ghi chú"
              outlined
              dense
              prepend-inner-icon="mdi-note"
              no-resize
            ></v-textarea>
          </v-col>
        </v-row>
      </v-layout>
      <ProductComponent
        :productsList="productsList"
        v-model="form.ProductPriceRequestsDetails"
        title="Sản phẩm yêu cầu báo giá"
        :table="TABLE"
        key-price="expected_price"
        show-import-excel
      />
    </v-form>
  </PageListLayout>
</template>
<script>
import {
  addProductPriceRequest,
  editProductPriceRequest,
  updateProductPriceRequest,
  getProductPriceRequestById,
  getProductPriceRequestCount,
  printProductPriceRequest,
} from "@/api/yeucaubaogia";
import { getAllProductTypes } from "@/api/loaisanpham";
import KHACHHANG from "@/api/khachhang";
import chatThaiAPI from "@/api/sx.chatthai.api";
// import { getAllProducts } from "@/api/sanpham";
// import avatarNone from "../../../../docs/img/avatar_none.png";
import { TABLE, RequestTypeList, UNITS } from "@/constants/yeucaubaogia";
import { store, update, destroy } from "@/api/productdetail.api";
import { getContracts } from "@/api/hopdong";
import JsonToFromData from "@/utils/JsonToFormData.js";
import { numberFormat } from "@/utils/moneyFormat";
import { saveAs } from "file-saver";
import ProductComponent from "../SanPhamComponent/Product.vue";
export default {
  components: {
    ProductComponent,
  },
  data: () => ({
    TABLE,
    UNITS,
    loadingCode: false,
    page: 1,
    btnLoadingPrint: false,
    productTypesList: [],
    showPickRequestDate: false,
    showPickResponseDate: false,
    date: null,
    editing: false,
    customersList: [],
    productsList: [],
    ProductData: [],
    requestTypeList: RequestTypeList,
    tab: null,
    btnLoading: false,
    codeRules: [(v) => !!v || "Mã yêu cầu không thể bỏ trống"],
    request_dateRules: [
      (v) => !!v || "Ngày yêu cầu báo giá không thể bỏ trống",
    ],
    response_dateRules: [
      (v) => !!v || "Ngày đề nghị trả kết quả không thể bỏ trống",
    ],
    request_typeRules: [(v) => !!v || "Loại báo giá không thể bỏ trống"],
    customer_idRules: [(v) => !!v || "Khách hàng không thể bỏ trống"],
    contract_idRules: [(v) => !!v || "Mã hợp đồng không thể bỏ trống"],
    bonusContract_idRules: [
      (v) => !!v || "Mã hợp đồng bổ sung không thể bỏ trống",
    ],
    form: {
      code: "",
      request_date: "",
      request_type: "",
      customer_id: "",
      customer_name: "",
      customer_address: "",
      status: null,
      description: "",
      ProductPriceRequestsDetails: [],
      response_date: null,
    },
    listContract: [],
    listBonusContract: [],
  }),

  created() {
    if (this.$route.params.id) {
      this.editing = true;
      this.getProductPriceRequestInfo(this.$route.params.id);
    } else {
      if (this.$route.query.bansao) {
        this.getProductPriceRequestInfo(this.$route.query.bansao);
      }
      this.fetchProductPriceRequestCount();
    }
  },

  mounted() {
    this.getCustomersList();
    this.getProductsList();
    this.getProductTypesList();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Thêm sản phẩm" : "Sửa sản phẩm";
    },
  },
  watch: {
    "form.customer_id": function(val) {
      this.getContract(val);
      this.getBonusContract(val);
    },
  },
  methods: {
    saveChangeProduct(data) {
      this.form.ProductPriceRequestsDetails = data;
    },
    async fetchProductPriceRequestCount() {
      try {
        this.loadingCode = true;
        const res = await getProductPriceRequestCount();
        this.form.code = `YCBG${res}`;
      } finally {
        this.loadingCode = false;
      }
    },
    async getContract(customer_id) {
      const res = await getContracts({
        customer_id,
        pagination: false,
      });
      this.listContract = res;
    },
    // get api Bổ sung
    async getBonusContract(customer_id) {
      const res = await getContracts({
        customer_id,
        pagination: false,
      });
      this.listBonusContract = res;
    },
    async getProductPriceRequestInfo(id) {
      let data = await getProductPriceRequestById({ id });
      this.form = data;
      this.$set(
        this.form,
        "ProductPriceRequestsDetails",
        data.product_price_requests_details
      );
    },
    async submit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          let data = { ...this.form };
          await addProductPriceRequest(JsonToFromData(data, null));
          this.alert(true);
          this.$router.push("/kinhdoanh/yeucaubaogia");
        } catch {
          this.$store.dispatch("toastAlert/error", `Dữ liệu không hợp lệ`);
        } finally {
          this.show = false;
          this.btnLoading = false;
        }
      }
    },
    alert(isCreate) {
      this.$emit("on-done");
      this.$store.dispatch(
        "toastAlert/success",
        `${isCreate ? "Thêm mới" : "Cập nhật"} thành công`
      );
    },
    async getProductTypesList() {
      this.productTypesList = await getAllProductTypes();
    },
    async getCustomersList() {
      let res = await KHACHHANG.getAllActiveCustomers();
      this.customersList = res.data;
      console.log('customersList',this.customersList);
    },
    async getProductsList() {
      let res = await chatThaiAPI.list();
      this.productsList = res.data;
    },
    async submitUpdate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          let data = { ...this.form };
          data = JsonToFromData(data);
          console.log(data);
          await updateProductPriceRequest(this.form.id, data);
          this.show = false;
          this.btnLoading = false;
          this.alert(false);
          this.$router.push("/kinhdoanh/yeucaubaogia");
        } catch (error) {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },

    onChangeCustomers(event) {
      this.form.customer_name = this.customersList.find(
        (item) => item.id === event
      ).name;
      this.form.customer_address = this.customersList.find(
        (item) => item.id === event
      ).address;
    },
    async print(item) {
      try {
        this.btnLoadingPrint = true;
        const res = await printProductPriceRequest(item.id);
        if (res.isError) {
          this.$store.dispatch("toastAlert/error", "Lỗi in yêu cầu báo giá");
          return;
        }
        saveAs(new Blob([res]), "Chi_tiet_yeu_cau_bao_gia.docx");
      } catch (error) {
        this.$store.dispatch("toastAlert/error", "Lỗi in yêu cầu báo giá");
      } finally {
        this.btnLoadingPrint = false;
      }
    },
  },
};
</script>
<style scoped>
.zero-vertical {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
::v-deep.v-list-item.header__right__list_item {
  height: 40px;
}
::v-deep .header__right__list_item .v-list-item__content {
  padding-bottom: 0;
}
</style>
